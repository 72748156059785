import { useStore } from "@nanostores/preact";
import { isCartModified, isCartOpen, showCartItemQuantity } from "./cartStore";

export default function CartFlyoutToggle() {
    const $isCartOpen = useStore(isCartOpen);
    const $isCartModified = useStore(isCartModified);
    
    function showToCartQuantity(){
        return showCartItemQuantity();
    }

    const buttonClasses = "flex items-center justify-center space-x-2 bg-blue-600 hover:bg-blue-700 text-white text-sm font-medium py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50";

    return (
        <button 
            className="flex items-center justify-center space-x-2 bg-gray-600 hover:bg-gray-700 text-white text-sm font-medium py-2 px-4 rounded-sm transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-800 focus:ring-opacity-50"
            onClick={() => isCartOpen.set(!$isCartOpen)}
            aria-label={$isCartModified ? "Ver carrito modificado" : "Ver carrito"}
        >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24">
                <path fill="currentColor" d="M17 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2M1 2v2h2l3.6 7.59l-1.36 2.45c-.15.28-.24.61-.24.96a2 2 0 0 0 2 2h12v-2H7.42a.25.25 0 0 1-.25-.25c0-.05.01-.09.03-.12L8.1 13h7.45c.75 0 1.41-.42 1.75-1.03l3.58-6.47c.07-.16.12-.33.12-.5a1 1 0 0 0-1-1H5.21l-.94-2M7 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2"/>
            </svg>
            <span className="hidden sm:inline">Carrito</span>
            <span className="inline-flex items-center justify-center w-6 h-6 bg-red-500 text-white text-xs font-bold rounded-full">
                {showToCartQuantity()}
            </span>
        </button>
    );
}

