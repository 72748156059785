import { useStore } from '@nanostores/preact'
import { isCartOpen, cartItems, removeCartItem, updateCartItemQuantity } from './cartStore'

export default function CartFlyout(){
    const $isCartOpen = useStore(isCartOpen);
    const $cartItems = useStore(cartItems);

    function removeToCart(cartItem){
        removeCartItem(cartItem);
        isCartOpen.set(false);
        isCartOpen.set(true);
    }

    function updateToCartQuantity(cartItem){
        updateCartItemQuantity(cartItem);
    }

    if (!$isCartOpen) return null;

    return (
        <aside className="fixed inset-y-0 right-0 w-64 sm:w-80 bg-white shadow-lg z-50 overflow-y-auto transform transition-transform duration-300 ease-in-out">
            <button 
                onClick={() => isCartOpen.set(false)}
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300 rounded-full p-2 transition duration-300 ease-in-out"
                aria-label="Cerrar carrito"
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
            <div className="p-4 bg-gray-100 border-b border-gray-200 flex justify-between items-center">
                <h2 className="text-lg font-semibold text-gray-800">Tu Carrito</h2>
            </div>
            {Object.values($cartItems).length ? (
                <div className="divide-y divide-gray-200">
                    <nav className="p-4 space-y-2">
                        <a href="/contacto/cotizar" className="block w-full text-center py-2 px-4 bg-red-600 hover:bg-red-700 text-white text-sm font-medium rounded transition duration-300 ease-in-out">
                            Cotizar herramientas
                        </a>
                        <a href="/contacto/demo" className="block w-full text-center py-2 px-4 bg-slate-600 hover:bg-slate-700 text-white text-sm font-medium rounded transition duration-300 ease-in-out">
                            Demostración de herramientas
                        </a>
                    </nav>
                    <ul className="p-4 space-y-4">
                        {Object.values($cartItems).map(cartItem => (
                            <li key={cartItem.id} className="flex flex-col items-center bg-white rounded-lg shadow-md p-4">
                                <img className="w-32 h-32 object-cover rounded" src={cartItem.imageSrc || "/placeholder.svg"} alt={cartItem.name} />
                                <h3 className="mt-2 text-sm font-medium text-gray-900">Modelo: {cartItem.name}</h3>
                                <h4 className="text-xs text-gray-600">Ref: {cartItem.id}</h4>
                                <div className="mt-2 flex items-center space-x-2">
                                    <label htmlFor={`itemQuantity${cartItem.id}`} className="text-sm text-gray-700">Cantidad:</label>
                                    <input 
                                        type="number" 
                                        id={`itemQuantity${cartItem.id}`} 
                                        value={cartItem.quantity} 
                                        min={1} 
                                        className="w-16 px-2 py-1 text-sm border rounded focus:outline-none focus:ring-2 focus:ring-blue-500" 
                                        onChange={() => updateToCartQuantity(cartItem)} 
                                    />
                                </div>
                                <button 
                                    className="mt-3 w-full bg-red-600 hover:bg-red-700 text-white text-sm font-medium py-2 px-4 rounded transition duration-300 ease-in-out"
                                    onClick={() => removeToCart(cartItem)}
                                >
                                    Eliminar
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : (
                <div className="p-4 space-y-4">
                    <p className="text-center text-base font-medium bg-slate-600 text-white py-3 rounded">¡El carrito está vacío!</p>
                    <a href="/herramientas" className="block w-full text-center py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white text-sm font-medium rounded transition duration-300 ease-in-out">
                        Encuentra tus herramientas
                    </a>
                </div>
            )}
        </aside> 
    );
}

